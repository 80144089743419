import './App.css';

function App() {
  return (
    <div id="page-top">
      <div id="apDiv2">
        <a href="#myModal">
          <img src="img/download.png" alt="Saket Codename Upgrade Brochure" title="Saket Codename Upgrade brochure" className="apDiv1"  data-toggle="modal" data-target="#myModal"/>
          </a>
      </div>

      <div id="apDiv4">
        <div className="apDiv4-call" data-toggle="modal" data-target="#myModals">
          <i className="fa fa-car faa-tada animated"></i>&ensp;&ensp;Site Visit
        </div>
      </div>
      <div id="apDiv5">
        <div className="apDiv5-call"><a href="tel:+919130568016">
          <i className="fa fa-phone faa-tada animated"></i>&ensp;+91 9130568016</a>
        </div>
      </div>

      <div id="myModal" className="modal fade" role="dialog">
         <div className="modal-dialog">
            <div className="modal-content">
               <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal">&times;</button>
                  <h4 className="modal-title pop_align">Saket Codename Upgrade Kalyan Brochure</h4>
               </div>
               <div className="modal-body">
				 
			   
					  <form action="maildownload.php" method="post">
						 <div className="form-group">
							<div className="input-group"> 
							   <span className="input-group-addon"><i className="fa fa-user"></i></span>
							   <input className="form-control" name="fname" id="fnames" placeholder="Name" required />
							</div>
						 </div>
						 <div className="form-group">
							<div className="input-group"> 
							   <span className="input-group-addon"><i className="fa fa-envelope"></i></span>
							   <input type="email" className="form-control" name="email" id="emails" placeholder="Email" required />
							</div>
						 </div>
						 <div className="form-group">
							<div className="input-group"> 
							   <span className="input-group-addon"><i className="fa fa-phone-square" ></i></span>
							   <input type="text" className="form-control" name="mobile" id="mobiles" placeholder="Mobile" required />
							</div>
						 </div>
						 
						 <div className="pop_align">
							<div className="form-group">
							   <input type="submit" name="save" className="btn btn-sl-form"  value="Submit" />
							</div>
						 </div>
						 <div className="success_message1 pop_align"></div>
					  </form>
				  
				  
               </div>
            </div>
         </div>
      </div>
	  
	  
		<div id="ganesh" className="modal fade" role="dialog">
			<div className="modal-dialog modal-sm">
				<div className="modal-content">
					<div className="modal-header">
						<button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
						<h4 className="modal-title pop_align">Book Free Site Visit</h4>
					</div>
					<div className="modal-body">
					  
					<form  method="post" id="sitevisit">
						<div className="form-group">
							<div className="input-group"> 
								<span className="input-group-addon"><i className="fa fa-user"></i></span>
								<input className="form-control" name="fname" id="fname1" placeholder="Name *" required />
							</div>
						</div>
						 <div className="form-group">
							<div className="input-group"> 
								<span className="input-group-addon"><i className="fa fa-phone-square" ></i></span>
										 <input type="text" className="form-control" name="mobile" id="mobile1" placeholder="Mobile *" required />
							</div>
						</div>
						<div className="form-group">
							<div className="input-group"> 
								<span className="input-group-addon"><i className="fa fa-calendar "></i></span>
								<input type="date" className="form-control" name="date" id="date1" placeholder="Date *" required />
				   
							</div>
						</div>
						<div className="form-group">
							<div className="input-group"> 
								<span className="input-group-addon"><i className="fa fa-map-marker"></i></span>
								<textarea className="form-control" name="location" id="location2" placeholder="Location *" required></textarea>
							</div>
						</div>
					  <div className="pop_align">
					 <div className="form-group">
						<input type="submit" className="btn btn-sl-form" value="Submit"/>
					 </div>
					</div>
					<div className="success_offerspop pop_align"></div>
				</form>
					</div>
				</div>
			</div>
		</div>
		
		
		<div id="pricing" className="modal fade" role="dialog">
			<div className="modal-dialog modal-sm">
				<div className="modal-content">
					<div className="modal-header">
						<button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
						<h4 className="modal-title pop_align">Get Offer Price</h4>
					</div>
					<div className="modal-body">
					   
					<form method="post" id="pricing1">
						<div className="form-group">
							<div className="input-group"> 
								<span className="input-group-addon"><i className="fa fa-user"></i></span>
								<input className="form-control" name="fname" id="fnameprice" placeholder="Name *" required />
							</div>
						</div>
						 <div className="form-group">
							<div className="input-group"> 
								<span className="input-group-addon"><i className="fa fa-phone-square" ></i></span>
								<input type="text" className="form-control" name="mobile" id="mobileprice" placeholder="Mobile *" required />
							</div>
						</div>
						
					  <div className="pop_align">
					 <div className="form-group">
						<input type="submit" className="btn btn-sl-form" value="Submit"/>
					 </div>
					</div>
					<div className="success_price pop_align"></div>
				</form>
					</div>
				</div>
			</div>
		</div>
		
		<div id="floorplan" className="modal fade" role="dialog">
			<div className="modal-dialog modal-sm">
				<div className="modal-content">
					<div className="modal-header">
						<button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
						<h4 className="modal-title pop_align">Show Unit Plans</h4>
					</div>
					<div className="modal-body">
					  
					<form  method="post" id="floorplan1">
						<div className="form-group">
							<div className="input-group"> 
								<span className="input-group-addon"><i className="fa fa-user"></i></span>
								<input className="form-control" name="fname" id="fnamefloor" placeholder="Name *" required />
							</div>
						</div>
						 <div className="form-group">
							<div className="input-group"> 
								<span className="input-group-addon"><i className="fa fa-phone-square" ></i></span>
										 <input type="text" className="form-control" name="mobile" id="mobilefloor" placeholder="Mobile *" required />
							</div>
						</div>
						
					  <div className="pop_align">
					 <div className="form-group">
						<input type="submit" className="btn btn-sl-form" value="Submit"/>
					 </div>
					</div>
					<div className="success_floor pop_align"></div>
				</form>
					</div>
				</div>
			</div>
		</div>



      <div id="myModals" className="modal fade">
         <div className="modal-dialog">
            <div className="modal-content">
               <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                  <h4 className="modal-title pop_align">Get in touch for Saket Codename Upgrade Kalyan </h4>
               </div>
               <div className="modal-body">
						 
			   
			   
                  <form  method="post" id="signupForm2">
                     <div className="form-group">
                        <div className="input-group"> 
                           <span className="input-group-addon"><i className="fa fa-user"></i></span>
                           <input className="form-control" name="fname" id="fnameb" placeholder="Name" required />
                        </div>
                     </div>
                     <div className="form-group">
                        <div className="input-group"> 
                           <span className="input-group-addon"><i className="fa fa-envelope"></i></span>
                           <input type="email" className="form-control" name="email" id="emailb" placeholder="Email" required />
                        </div>
                     </div>
                     <div className="form-group">
                        <div className="input-group">
                           <span className="input-group-addon"><i className="fa fa-phone-square" ></i></span>
                           <div className="col-lg-3">
                              <div className="row">
                                 <select name="code" id="codes" className="form-control" >
                                    <option  value="">Select Country</option>
                                    <option value="+91">India</option>
                                    <option value="+971">United Arab Emirates</option>
                                    <option value="+1">USA</option>
                                    <option value="+1">Canada</option>
                                    <option value="+44">United Kingdom</option>
                                    <option value="+61">Australia</option>
                                    <option value="+65">Singapore</option>
                                    <option value="+974">Qatar</option>
                                    <option value="+968">Oman</option>
                                    <option value="+64">New Zealand</option>
                                    <option value="+60">Malaysia</option>
                                    <option value="+852">Hong Kong</option>
                                    <option value="+0">Others</option>
                                 </select>
                              </div>
                           </div>
                           <div className="col-lg-9">
                              <div className="row">
                                 <input type="text" className="form-control" name="mobile" id="mobileb" placeholder="Mobile" />
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="form-group">
                        <div className="input-group"> 
                           <span className="input-group-addon"><i className="fa fa-comment"></i></span>
                           <textarea className="form-control" name="mesg" id="mesgb" placeholder="Massage" rows="3"></textarea>
                        </div>
                     </div>
                     <div className="pop_align">
                        <div className="form-group">
                           <input type="submit" className="btn btn-sl-form" value="Submit" />
                        </div>
                     </div>
                     <div className="success_message2 pop_align"></div>
                  </form>
               </div>
            </div>
         </div>
      </div>

      <div id="feedback">
         <div id="feedback-form" className="col-xs-4 col-md-4 panel panel-default displaynone">
            <h3>Instant Call Back</h3>
            <form  method="post" id="signupForm3">
               <div className="form-group">
                  <div className="input-group"> 
                     <span className="input-group-addon"><i className="fa fa-user"></i></span>
                     <input className="form-control" name="fname" id="fnamec" placeholder="Name" />
                  </div>
               </div>
               <div className="form-group">
                  <div className="input-group"> 
                     <span className="input-group-addon"><i className="fa fa-envelope"></i></span>
                     <input type="email" className="form-control" name="email" id="emailc" placeholder="Email" />
                  </div>
               </div>
               <div className="form-group">
                  <div className="input-group"> 
                     <span className="input-group-addon"><i className="fa fa-phone-square" ></i></span>
                     <input type="text" className="form-control" name="mobile" id="mobilec" placeholder="Mobile" />
                  </div>
               </div>
               <div className="pop_align">
                  <div className="form-grop">
                     <input type="submit" className="btn btn-sl-form" value="Submit"/>
                  </div>
               </div>
               <div className="success_message3 float_left"></div>
            </form>
         </div>
         <div id="feedback-tab">Instant Call Back</div>
      </div>
    
      <nav id="mainNav" className="navbar navbar-default navbar-fixed-top" >
         <div className="container">
            <div className="navbar-header">
	 
               <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1">
               <span className="sr-only">Toggle navigation</span> Menu <i className="fa fa-bars"></i>
               </button>
			    
               <a className="navbar-brand page-scroll" href="#page-top">
               <img src="img/logoupgrade.jpg" width="173" className="img-responsive logoweb" alt="Saket Codename Upgrade" title="Saket Codename Upgrade" />
               </a>
            </div>
            <div className="cell" >
               <a href="tel:+919130568016">
                  <div className="col-md-2 cell_phone"><i className="fa fa-phone"></i>&ensp;+91 9130568016</div>
               </a>
            </div>
            <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
               <ul className="nav navbar-nav navbar-right">
                  <li><a className="page-scroll" href="#page-top">Home</a></li>
                  <li><a className="page-scroll" href="#about">About</a></li>
				  <li><a className="page-scroll" href="#rate">Configuration</a></li>
                  <li><a className="page-scroll" href="#amenities">Amenities</a></li>
                  <li><a className="page-scroll" href="#plans">Plan</a></li>
                  <li><a className="page-scroll" href="#location">Location</a></li>
                  <li><a className="page-scroll" href="#contact">Contact</a></li>
               </ul>
            </div>
         </div>
      </nav>
    
 
 
		 <header className="md-3form hero-bkg-animated ">
		 <div className="bacl" style={{background:"rgba(0, 0, 0, 0.8)", padding:"2% 0%"}}>
		 <div className="container">
	     <div className="row">
	     
			<div className="col-md-7 col-md-91 sld">
				<div className="row"> 
				<h4>A 6-Acre Luxury Community with Smart Lifestyle Avenues</h4>
				<h4>Starting Just @ ₹44 Lacs Onwards*</h4>
		 
 
				 <div id="myCarousel" className="carousel slide " data-ride="carousel">
					 <ol className="carousel-indicators">
						<li data-target="#myCarousel" data-slide-to="0" className="active"></li>
						<li data-target="#myCarousel" data-slide-to="1"></li>
						<li data-target="#myCarousel" data-slide-to="2"></li>
						 
					  </ol>
				<div className="carousel-inner">
					<div className="item active">
						<img className="d-banner1" style={{width:"100%"}} src="img/saket1.jpg" alt="Saket Upgrade Kalyan"/>
					</div>
					<div className="item">
						<img className="d-banner1" style={{width:"100%"}} src="img/saket0.jpg" alt="Saket Upgrade"/>
					</div>	
					<div className="item ">
						<img className="d-banner1" style={{width:"100%"}} src="img/saket2.jpg" alt="Saket Codename Upgrade Kalyan"/>
						</div>
					</div>
					
					<a className="left carousel-control" href="#myCarousel" data-slide="prev">
					<i className="fa fa-arrow-left slicon-left"></i><span className="sr-only">Previous</span></a>
					<a className="right carousel-control" href="#myCarousel" data-slide="next"><i className="fa fa-arrow-right slicon-right"></i><span className="sr-only">Next</span></a>
					</div>
					</div>
					
					 <p className="high_label1">
					 <span className="animated bounceIn infinite high_label_span1">
						PRE-REGISTER NOW - @21,00/-
					</span>
						SAVE UPTO 2.67 LACS BENEFITS | 2 Balconies with 1 Terrace of 50sqft for both 1 & 2BHK 🏬

					</p>
				</div> 

				<div className="col-md-4 col-md-offset-1 col-md-31 ">
				<div className="row">
				  <div className="sliderform1">
				 				 
				 <h4>Location In Kalyan Navi Mumbai</h4>
                     <p className="pop_align"><img className="" src="img/0.png" alt="Saket Codename Upgrade"/></p>
					 <div className="form-title">
						<ul>
						    <li>6 Magnificient Towers ranging from 22 to 24 Storeys</li>
						    <li>IGBC Pre-Gold Certified-Digi-smart Lifestyle Homes</li>
						    <li>4 Levels of Clubhouse Recreations</li>
						    <li>2.26 Acres of Ground Level Recreations</li>							
						    <li>Double-height Grand Entrance Lobby</li>							
						    <li>1.45 Acres Podium Indulgences</li>							
						</ul>		
													
					</div><br/>
					 
					 <div className="col-md-12 ">
					 <div className="row">
                     <form method="post" id="formmobile">
                     <div className="form-group">
                        <input className="form-control form_mob_field" name="fname" id="fnamemid" placeholder="Name" required />
                     </div>
                     <div className="form-group">
                        <input type="email" className="form-control form_mob_field" name="email" id="emailmid" placeholder="Email" required />
                     </div>
                     <div className="form-group">
                        <input type="text" className="form-control form_mob_field" name="mobile" id="mobilemid" placeholder="Mobile" required />
                     </div>
                     					 
					 <div className="pop_align">
                        <div className="form-group">
                           <input type="submit" className="btn btn-sl-form-sld" value="Submit" />
                        </div>
                     </div>
					 
                     <p><div className="success_mobile pop_align"></div> </p>
                  </form>
				  </div>
				  </div>
 
				<h5> MahaRERA No.: P51700024797 </h5>
				<div className="sliderformhr"></div>
					<button type="submit" data-toggle="modal" data-target="#ganesh" className="btn btn-sl-form-sld"> <i className="fa fa-car faa-tada animated"></i>&ensp;Book Free Site Visit</button> 
				  </div>
				
				</div> 
				</div> 
	   </div>
	   </div>
	   </div>
    </header>
	 
	
      <section className="bg-primary1" id="about">
         <div className="container">
            <div className="row">
                
                  <h1 className="section-heading sectionheading1 text-center">Saket Codename Upgrade</h1>
                  <hr className="light1"/> 
				  <div className="col-lg-12 text-center">
				 		<p><b>New Launch Project Saket Codename Upgrade at Kalyan. MAHARERA NO. P51700024797</b></p>
						 
						<p>Kalyan's first and biggest ever pre launch the first time in a private development in Kalyan. JIO Powered Township in Kalyan. </p>
						<p>Presenting <a href="https://saketcodenameupgrade.pre-launch.co/" class="url" ><b>Saket Codename Upgrade</b></a> Kalyan. An iconic landmark that represent the best of Kalyan Thane. and Kalyan with its internationally-styled multi-area, mixed-use development. with iconic residential, commercial, retail and lifestyle avenies, designed and maintained by international experts. Saket Codename Upgrade is Kalyan biggest development spread across 6 acres. Saket Codename Upgrade Kalyan is a striking example of meticulous planning, stunning features and architectural innovation.</p>	
						<p>Saket Codename Upgrade is the Pre Launch Project by Saket Developers in Kalyan. Saket Group Codename Upgrade defined by its exclusive location, architecture, amenities & connectivity. Saket Codename Upgrade Kalyan Spread in the 35 acers of huge land parcel. Urban Centre City Kalyan offers you excellent private views and is designed to offer optimum cross ventilation thus providing access to natural breeze and Sunlight. Throw open your windows to a draft of the coolest, most sublime breeze and stunning views of green.</p>
						
						<div className="form-group"><input type="submit" data-toggle="modal" data-target="#ganesh" className="btn btn-sl-form" value="Pick Up & Drop Facility" /></div>
						 
               </div>
			    
            </div>
         </div>
      </section>
    
	  <section id="rate" className="rate">
         <div className="container rate-sub1">
            <div className="row">
               <div className="col-lg-12 text-center">
                  <h2 className="section-heading-rate sectionheading2">Configuration</h2>
                  <hr className="color"/>
               </div>
            </div>
            <div className="col-lg-12 col-sm-12">
               <div className="row">
                  <table className="table table_pr" >
					<thead >
						<tr>
							<th className="table_th">Configuration</th>
							<th className="table_th">Usable Carpet 🏘️</th>
							<th className="table_th">Price</th>
						</tr>
					</thead>
					<tbody className="table_tbody">
						<tr>
							<td className="bg-grey-table1">Very Spacious 1 BHK</td>
							<td className="bg-grey-table1">510 - 550 sq.ft.</td>
							<td className="bg-grey-table2">
							<a className="price-click" href="#" data-toggle="modal" data-value="2 BHK" data-target="#pricing">₹ 44 Lacs* + Taxes</a>
							</td>
						</tr>
						 
						<tr>
							<td className="bg-grey-table1">Very Spacious 2 BHK</td>
							<td className="bg-grey-table1">810- 850 sq.ft.</td>
							<td className="bg-grey-table2">
							<a className="price-click" href="#" data-toggle="modal" data-value="2 BHK" data-target="#pricing">Price On Request</a>
							</td>
						</tr>

						<tr>
							<td className="bg-grey-table1 note-size" colspan="3">✓ PMAY : Upto 2.67 Lacs Benefits</td>
						</tr>
						<tr>
							<td className="bg-grey-table2" colspan="3"><a href="#" data-toggle="modal" className="btn btn-price" data-target="#myModals">Enquire Now</a></td>
						</tr>

					</tbody>
				</table>  
               </div>
               <h3 className="pop_align1">For booking process please call us now: +91 91305 68016</h3>
            </div>
         </div>
      </section>
    
	  
      <section id="amenities">
         <div className="container amenities-sub1">
            <div className="row">
               <div className="col-lg-12 text-center">
                  <h2 className="section-heading sectionheading1">Amenities</h2>
                  <hr className="primary"/>
               </div>
            </div>
			
			
			<div className="row1">
						<div className="col-md-2 col-xs-6">
							<div className="ame">
								<img src="img/amenities/clubhouse.png" alt="" height="75"/>
								  <p> Club House </p>
							</div>
						</div>
						<div className="col-md-2 col-xs-6">
							<div className="ame">
								<img src="img/amenities/running.png" alt="" height="75"/>
								  <p> Jogging Track</p>
							</div>
						</div>
						<div className="col-md-2 col-xs-6">
							<div className="ame">
								<img src="img/amenities/dumble.png" alt="" height="75"/>
								  <p> Gymnasium </p>
							</div>
						</div>
						<div className="col-md-2 col-xs-6">
							<div className="ame">
								<img src="img/amenities/squash.png" alt="" height="75"/>
								  <p> Squash Court </p>
							</div>
						</div>
						<div className="col-md-2 col-xs-6">
							<div className="ame">
								<img src="img/amenities/pool.png" alt="" height="75"/>
								  <p> Swimming Pool</p>
							</div>
						</div>
						<div className="col-md-2 col-xs-6">
							<div className="ame">
								<img src="img/amenities/hall.png" alt="" height="75"/>
								  <p> Multipurpose Hall</p>
							</div>
						</div>

						<div className="col-md-2 col-xs-6">
							<div className="ame">
								<img src="img/amenities/cafe.png" alt="" height="75"/>
								  <p> Cafe</p>
							</div>
						</div>
						<div className="col-md-2 col-xs-6">
							<div className="ame">
								<img src="img/amenities/gamepad.png" alt="" height="75"/>
								  <p> Indoor Game Room</p>
							</div>
						</div>
						<div className="col-md-2 col-xs-6">
							<div className="ame">
								<img src="img/amenities/medtation.png" alt="" height="75"/>
								  <p>Yoga Lawn</p>
							</div>
						</div>
						<div className="col-md-2 col-xs-6">
							<div className="ame">
								<img src="img/amenities/reflexology.png" alt="" height="75"/>
								  <p> Reflexology Pool</p>
							</div>
						</div>
						<div className="col-md-2 col-xs-6">
							<div className="ame">
								<img src="img/amenities/kids-pay.png" alt="" height="75"/>
								  <p> Kid's Play Area</p>
							</div>
						</div>
						<div className="col-md-2 col-xs-6">
							<div className="ame">
								<img src="img/amenities/senior.png" alt="" height="75"/>
								  <p> Senior Citizen's Area</p>
							</div>
						</div>
						
						<div className="col-md-2 col-xs-6">
							<div className="ame">
								<img src="img/amenities/ami.png" alt="" height="75"/>
								  <p> Amphith eatre</p>
							</div>
						</div>
						<div className="col-md-2 col-xs-6">
							<div className="ame">
								<img src="img/amenities/lib.png" alt="" height="75"/>
								  <p> E-Library</p>
							</div>
						</div>
						<div className="col-md-2 col-xs-6">
							<div className="ame">
								<img src="img/amenities/park.png" alt="" height="75"/>
								  <p> Central Greens</p>
							</div>
						</div>
						<div className="col-md-2 col-xs-6">
							<div className="ame">
								<img src="img/amenities/cctv.png" alt="" height="75"/>
								  <p> Security</p>
							</div>
						</div>
						 
					</div>  
 
	   
         </div>
      </section>


      <section id="plans"  className="bg-primary">
         <div className="container">
            <div className="row">
               <div className="col-lg-12 text-center">
                  <h2 className="section-heading sectionheading1">Saket Codename Upgrade Floor Plans </h2>
                  <hr className="primary"/>
               </div>
            </div>
         </div>
         <div className="container">
            <div className="row no-gutter  wow fadeInUp">
               <div className="col-lg-10 col-sm-10 col-xs-12 col-md-offset-2">
				   <div className="col-md-4 col-xs-12">
						<h3 style={{textAlign:"center"}}>Layout Map</h3>
					  <a href="#" data-toggle="modal" data-value="2 BHK" data-target="#floorplan" >
						 <img src="img/floorplan/layout.jpg"  className="img-responsive" alt="Saket Codename Upgrade" title="Saket Codename Upgrade"/>
						  
					  </a>
				   </div>
				   <div className="col-md-2 col-xs-12">
				   </div>
				   <div className="col-md-4 col-xs-12">
					<h3 style={{textAlign:"center"}}>Floor Plans</h3>
					  <a href="#" data-toggle="modal" data-value="2 BHK" data-target="#floorplan" >
						 <img src="img/floorplan/floorplan-hinde.jpg" className="img-responsive" alt="Saket Codename Upgrade" title="Saket Codename Upgrade"/>
						  
					  </a>
				   </div>
               </div>
               			   
			   
            </div>
         </div>
      </section>
    
      
      <section id="location">
         <div className="container">
            <div className="row">
               <div className="col-lg-12 text-center">
                  <h2 className="section-heading sectionheading1">Location</h2>
                  <hr className="primary"/>
               </div>
            </div>
         </div>
         <div className="container">
            <div className="row">
				<div className="col-lg-7 col-sm-7 pop_align">
					<div className="col-lg-12 col-sm-12">	
						 <a href="#myModals">
						 <img src="img/location.jpg" alt="Location Saket Codename Upgrade" title="Saket Codename Upgrade" data-toggle="modal" data-target="#myModals" className="img-responsive pop_align" /><br/>
						 </a>
					</div>
			    </div> 
				<div className="col-sm-5">
					<div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
						<div className="panel panel-default">
							<div className="panel-heading" role="tab" id="headingOne">
								<h4 className="panel-title">
									<a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
										<i className="more-less fa fa-plus" aria-hidden="true"></i>
										Transportation
									</a>
								</h4>
							</div>
							<div id="collapseOne" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
								<div className="panel-body">
									  <ul>
										<li>Kalyan Bus Depot - 2.5 kms</li>
										<li>Kalyan East Railway Station - 1.25 kms</li>
										<li>Vithalwadi Railway Station - 2.4 kms </li>
										<li>Vithalwadi Bus Depot - 2.4 kms</li>
										 
									  </ul>
								</div>
							</div>
						</div>

						<div className="panel panel-default">
							<div className="panel-heading" role="tab" id="headingTwo">
								<h4 className="panel-title">
									<a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
										<i className="more-less fa fa-plus" aria-hidden="true"></i>
										Educational Institutes
									</a>
								</h4>
							</div>
							<div id="collapseTwo" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
								<div className="panel-body">
									<ul>
										<li>Pramod Ramajugar Tiwari Saket Institute of Management - 1km</li>
										<li>Saket College of Arts, Science & Commerce - 1km</li>
										<li>Saket College of Education(B.Ed) - 1km </li>
										<li>Saket Vidya Mandir English High School(state Board) - 1km</li>
										<li>Saket Institute of Nursing - 1km</li>
										<li> Arya Gurukul School(CBSE) - 1km</li>

									  </ul>
								</div>
							</div>
						</div>

						<div className="panel panel-default">
							<div className="panel-heading" role="tab" id="headingThree">
								<h4 className="panel-title">
									<a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
										<i className="more-less fa fa-plus" aria-hidden="true"></i>
										Hospitals
									</a>
								</h4>
							</div>
							<div id="collapseThree" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
								<div className="panel-body">
									<ul>
										<li>Fortis Hospital - 1.5 kms </li>
										<li>Metro Specialty Hospital - 500 mtrs</li>
										<li>Meera Hospital - 2.5 kms</li>
										<li>AM-PM Hospital - 2.5 km</li>
									 </ul>
								</div>
							</div>
						</div>
						<div className="panel panel-default">
							<div className="panel-heading" role="tab" id="headingfour">
								<h4 className="panel-title">
									<a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapsefour" aria-expanded="false" aria-controls="collapsefour">
										<i className="more-less fa fa-plus" aria-hidden="true"></i>
										Malls & Market
									</a>
								</h4>
							</div>
							<div id="collapsefour" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingfour">
								<div className="panel-body">
									<ul>
										<li>DMart - 2.5 kms</li>
										<li>Metro Mall - 1 km</li>
										<li>Big Bazar - 1 km</li>
										<li>Palava Xperia Mall - 6 kms</li>
									  </ul>
								</div>
							</div>
						</div>
						<div className="panel panel-default">
							<div className="panel-heading" role="tab" id="headingfive">
								<h4 className="panel-title">
									<a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapsefive" aria-expanded="false" aria-controls="collapsefive">
										<i className="more-less fa fa-plus" aria-hidden="true"></i>
										Restaurants & Hotels
									</a>
								</h4>
							</div>
							<div id="collapsefive" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingfive">
								<div className="panel-body">
									<ul>
										<li>Kashish International - 200 mtrs </li>
										<li>Prasad Food Divine - 1.2 kms</li>
										<li>Nandi Palace - 3 kms</li>
									  </ul>
								</div>
							</div>
						</div>
						
						
						</div>
				</div>
				<div class="col-lg-12 col-sm-12">	
						<h3 class="pop_align"><b>ADVANTAGES OF LOCATION</b></h3>
						<p><b>With its center location, ease of connectivity and superlative infrastructure, Kalyan offers Thane finest avenues in commerce, lifestyle and culture.</b></p>
						 
						<p><b>A new mega-hub of infrastructure and growth.</b>In recent times there has been a strong focus on Kalyan as the new centre of development with tremendous Government impetus, initiatives and investment. Besides high infrastructure growth, the superior air quality and  microclimate, dedicated open spaces, abundance of nature and excellent connectivity make Kalyan a true mega-destination of the future. </p>

				</div>
            </div>
			
         </div>
      </section>

      <aside className="bg-dark">
         <div className="container text-center">
            <div className="call-to-action">
               <h3>For more Information call : +91 91305 68016</h3>
               <a href="#myModal" data-toggle="modal" data-target="#myModal" className="btn btn-default btn-xl sr-button">Download Brochure!</a>
            </div>
         </div>
      </aside>

      
	  
	 

 <section id="contact"  >
         <div className="container">
            <div className="row">
				<div className="col-lg-6 text-center">
                  <h3 className="section-heading">About Saket Group</h3>
                  <hr className="light"/>
					
					<p>Established in 1980, Saket Group has created landmark developments to fulfill the housing needs of Mumbai, Pune & Thane on or before time consistently. Now, it is all set to redefine the complete landscape of Kalyan with it’s biggest planned township.</p>
               </div>
			   
               <div className="col-lg-6 text-center">
                  <h3 className="section-heading">Lets Get In Touch! </h3>
                  <hr className="primary"/>
                  <form method="post" id="contactinq">
                     <div className="form-group">
                        <div className="input-group"> 
                           <span className="input-group-addon"><i className="fa fa-user"></i></span>
                           <input className="form-control" name="fname" id="fname" placeholder="Name" required/>
                        </div>
                     </div>
                     <div className="form-group">
                        <div className="input-group"> 
                           <span className="input-group-addon"><i className="fa fa-envelope"></i></span>
                           <input type="email" className="form-control" name="email" id="email" placeholder="Email" required/>
                        </div>
                     </div>
                     <div className="form-group">
                        <div className="input-group"> 
                           <span className="input-group-addon"><i className="fa fa-phone-square" ></i></span>
                           <input type="text" className="form-control" name="mobile" id="mobile" placeholder="Mobile" required/>
                        </div>
                     </div>
                     <div className="form-group">
                        <div className="input-group"> 
                           <span className="input-group-addon"><i className="fa fa-comment"></i></span>
                           <textarea className="form-control" name="mesg" id="mesg" placeholder="Massage" rows="4"></textarea>
                        </div>
                     </div>
                     <div className="pop_align">
                        <div className="form-group">
                           <input type="submit" className="btn btn-sl-form" value="Submit"/>
                        </div>
                     </div>
                     <div className="success_message pop_align"></div>
                     <br/>
                  </form>
               </div>
               
			   <div className="col-lg-12 text-center">
                  <i className="fa fa-phone fa-3x sr-contact"></i>
                  <h3>+91 9130568016</h3>
               </div>
			   
			   
            </div>
         </div>
      </section>

      <div className="bg-dark">
         <div className="container text-center">
            <div>
				 <ul className="footer-menu">
					<li><a href="https://www.facebook.com/" target="_blank">Facebook</a></li> 
                    <li>|</li> 
                    <li><a href="https://plus.google.com/" target="_blank">Google +</a></li> 
                    <li>|</li> 
                    <li><a href="https://twitter.com/" target="_blank">Twitter</a></li>
                  
                </ul>
			   <div className="reranumber"><b>Project - MahaRERA No.:</b> P51700024797</div>
               <div className="disclaimer"><b>Disclaimer:</b>  This Website Is Managed By Saket Codename Upgrade Kalyan, Authorised Media Partner.</div>
          <br/><br/>
            </div>
         </div>
      </div>

      <div className="whatsapp-area">
         <div className="chat-button-area zoomOut">
            <button type="button" id="whatsapp-btn"  className="whatsapp-btn"> 
            <a href="https://wa.me/919082164514?text=Hi.. I am interested in Saket Codename Upgrade Project. Please help me with the details. Thanks!" target="_blank">
            <span className="icon-whatsapp"></span> <i className="fa fa-whatsapp"></i>
            </a>
            </button> 
            <div className="circle-animation">
            </div>
         </div>
      </div>
    
    </div>
  );
}

export default App;
